@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.inputCustom {
  height: 55px;
  border-radius: 6px;
  border: none;
  /* background-color: #323541 !important; */
  border: 1px solid hsl(0, 0%, 70%) !important;
  font-size: 14px;
  font-weight: 400;
  /* color: #ffffff !important; */
  /* color: #000 !important; */
  padding: 18px 27px 16px 27px;
  width: 100%;
  transition: all ease-in-out 0.3s;
}
.btnCustom {
  background: none;
  border-radius: 6px;
  color: #fff;
  padding: 9px 20px 8px;
  transition: all 0.5s;
  border: 1px solid #05baf3;
  color: #05baf3;
  font-weight: 700;
  font-size: 14px;
}
.btnCustom2 {
  border: 1px solid rgba(111, 108, 129, 0.3);
  border-radius: 6px;
  color: #fff;
  background: transparent;
  font-weight: 700;
  font-size: 14px;
  padding: 9px 20px 8px;
  transition: all 0.3s ease-in-out;
}
.btnCustom2:hover {
  color: #fff;
  text-decoration: none;
  border: 1px solid #05baf3;
}
.btnContainer {
  display: flex;
  justify-content: space-between;
  max-width: 335px;
  margin-left: auto;
  margin-right: auto;
}
